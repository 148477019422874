<template>
  <div class="saleSampleUnsettlement">
    <h1>未出结算单吨数指标</h1>
    <el-form :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" height="calc(100vh - 310px)">
      <el-table-column prop="custom" label="客户" show-overflow-tooltip />
      <el-table-column label="日期" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ util.parseTime(scope.row.sampleDateMin, '{y}-{m}-{d}') }}</el-text>
          <el-text v-if="scope.row.sampleDateMin != scope.row.sampleDateMax">{{ '~' + util.parseTime(scope.row.sampleDateMax, '{y}-{m}-{d}') }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="sumSampleQuantity" label="数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sumSampleQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="quanliu" label="平均硫" show-overflow-tooltip />
      <el-table-column prop="diweifareliangKc" label="平均热·大卡" show-overflow-tooltip />
      <el-table-column prop="huifafen" label="平均挥发" show-overflow-tooltip />
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
  sumSampleQuantity: null,
})

const show = reactive({
  selected: [],
})

const commitFindList = () => {
  data.list = []
  api.get('/backend/saleSample/unsettlement').then(res => {
    data.list = res.list
    data.sumSampleQuantity = res.sumSampleQuantity
  })
}
commitFindList()

const summary = () => {
  return ['合计', '', math.formatNumber(data.sumSampleQuantity)]
}
</script>

<style lang="less"></style>